<template>
  <div id="site" class="header-fixed">
    <header class="app-header fixed">
      <img src="@/assets/img/logo.svg" alt="logo" class="site-logo"/>
    </header>
    <div class="main-content">
      <slot/>
    </div>
    <footer class="purchase-footer">
<!--      <div class="company-label">-->
<!--        © 2021 APPITATE LLC-->
<!--      </div>-->
<!--      <div class="address">18401 Collins Ave STE 100-241, Sunny Isles Beach, Fl 33160</div>-->
      <div class="info">
        operated by ZEMETO based on the agreement<br>
        Zemeto Limited, 3021<br>
        Pavlou Nirvana & Aipeias, 4 Alpha Tower, 1st Floor, Flat/Office 11<br>
        Republic of Cyprus<br>
      </div>
    </footer>

    <!--    <footer class="main-footer">-->
    <!--      <div class="footer-bottom">-->
    <!--        <div class="footer-copyright">2021 ⓒ All Rights Reserved.</div>-->
    <!--        <div class="footer-agreement">-->
    <!--          Devurum Limited - HE 368090<br/>-->
    <!--          Agiou Marona 19, Lakatamia, Nicosia, P.C. 2304, Republic of Cyprus.<br/>-->
    <!--          <ul class="footer-politics-container">-->
    <!--            <li><a href="https://icall-recorder.com/onboarding/terms/">Terms of Service</a>|</li>-->
    <!--            <li><a href="https://icall-recorder.com/onboarding/policy/">Privacy policy</a>|</li>-->
    <!--            <li><a href="https://icall-recorder.com/onboarding/refund/">Money-Back Policy.</a></li>-->
    <!--          </ul>-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </footer>-->

  </div>
</template>

<script>
export default {
  name: 'AppLayout'
}
</script>

<style lang="scss" scoped>
#site {

  min-height: calc(100vh);
  background: #F4F4F8;

  &.header-fixed {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 70px;
  }

  .app-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 50px;
    background: #F4F4F8;
    z-index: 60;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      transition: all linear 0.1s;
    }

    .site-logo {
      display: block;
      object-position: left center;
      object-fit: scale-down;
    }

    .page-title {
      font-family: 'Roboto', sans-serif;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: #F7A826;
    }

    .btn-back {
      position: relative;
      width: 198px;
      height: 40px;
      background: rgba(255, 255, 255, 0.15);
      border: none;
      border-radius: 3px;
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: #FFFFFF;
      cursor: pointer;

      .arrow {
        position: absolute;
        left: 18px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s;
      }

      .icon-close {
        display: none
      }

      &:hover .arrow {
        left: 15px;
      }
    }
  }

  .main-content {
    background: #F4F4F8;
  }

  .purchase-footer {
    display: flex;
    padding-bottom: 25px;
    position: relative;
    top: 101px;
    justify-content: space-around;
    color: #AEAEAE;
    font-size: 13px;
    text-align: center;

    background: #F4F4F8;
  }

  .main-footer {
    padding: 0 10%;
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    padding-bottom: 64px;
  }

  .footer-politics-container {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .footer-politics-container {
    li:first-child {
      a {
        margin: 0 5px 0 0;
      }
    }

    li {

      a {


        font-size: 10px;
        line-height: 14px;
        color: #AEAEAE;
        margin: 0 5px;
      }
    }

  }

  .footer-copyright {
    font-size: 12px;
    line-height: 120%;
    color: #BCBCC6;
    text-align: left;
  }

  .footer-agreement {
    font-size: 14px;
    line-height: 21px;
    color: #AEAEAE;
    text-align: left;

  }

}
</style>

<style lang="scss" scoped>
@media (max-width: 768px) {

  #site {
    &.header-fixed {
      margin-top: 50px;
    }

    .app-header {
      height: 50px;
    }

    .main-footer {
      padding: 0px 5%;

      .footer-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-copyright {
          text-align: center;
        }

        .footer-agreement {
          text-align: center;

          .footer-politics-container {
            justify-content: center;
          }
        }
      }
    }
  }

  .purchase-footer {
    flex-direction: column;

    > div {
      text-align: center;

      &:not(:first-child) {
        margin-top: 15px;
      }
    }
  }

}
</style>

<style lang="scss" scoped>
@media (max-width: 325px) {
  .menu-wrapper {
    width: 300px;
  }
}
</style>
